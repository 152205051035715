import { forwardRef, useState } from 'react'
import { array, bool, func, string } from 'prop-types'

import { HelperText } from '../HelperText'

import { SelectBox, SelectField, SelectLabel, Wrapper } from './styles'

const propTypes = {
  'data-testid': string,
  defaultValue: string,
  disabled: bool,
  error: string,
  id: string,
  label: string,
  name: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  options: array,
  required: bool,
}

const Select = forwardRef((props, ref) => {
  const {
    defaultValue,
    disabled,
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    required,
    ...others
  } = props

  const [value, setValue] = useState('')
  const [isFocused, setFocused] = useState(false)

  const handleChange = e => {
    setValue(e?.target?.value)
    onChange?.(e)
  }

  const handleFocus = e => {
    setFocused(true)
    onFocus?.(e)
  }

  const handleBlur = e => {
    setFocused(false)
    onBlur?.(e)
  }

  if (!options?.length) return null

  return (
    <Wrapper>
      <SelectBox disabled={disabled}>
        <SelectLabel as="span" active={!!value || isFocused} error={error}>
          {label}
        </SelectLabel>
        <SelectField
          name={name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          id={id}
          ref={ref}
          disabled={disabled}
          required={required}
          defaultValue={defaultValue}
          value={value}
          {...others}
        >
          <option />
          {options.map((item, index) => (
            <option key={`${item.value}-${index}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </SelectField>
      </SelectBox>

      <HelperText>{error}</HelperText>
    </Wrapper>
  )
})

Select.displayName = 'Select'
Select.propTypes = propTypes

export default Select
