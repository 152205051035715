import { css, styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const Wrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`

export const SelectField = styled.select(
  ({ disabled, theme, value }) => css`
    ${getTypography('bodyMdRegular')({ theme })};
    appearance: none;
    background-color: transparent;
    border: none;
    bottom: 0;
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    left: 0;
    line-height: inherit;
    margin: 0;
    outline: none;
    padding-bottom: ${selectTheme('spacingX.xl')({ theme })};
    padding-left: ${selectTheme('spacingX.5xl')({ theme })};
    padding-right: ${selectTheme('spacingX.16xl')({ theme })};
    padding-top: ${selectTheme('spacingY.7xl')({ theme })};
    position: absolute;
    right: 0;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 100%;

    ${!!value &&
    `
      color: ${selectTheme('colors.neutral.90')({ theme })}
    `};

    ${!!disabled &&
    `
      color: ${selectTheme('colors.neutral.40')({ theme })}
    `};

    &::-ms-expand {
      display: none;
    }
  `,
)

export const SelectLabel = styled(RichText)(({ active, error, theme }) =>
  css(`
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: ${active ? `translate3d(0, -10px, 0)` : `translate3d(0, 0, 0)`};
    transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
    white-space: nowrap;
    width: calc(100% - 28px);
    line-height: 24px;
    ${
      !!active &&
      `
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.3;
    `
    };
    
    ${!!error && `color: ${selectTheme('colors.error.60')({ theme })}`}
  `),
)

export const SelectBox = styled.label`
  background: ${selectTheme('colors.neutral.10')};
  border-radius: ${selectTheme('radius.4xl')};
  color: ${({ disabled }) =>
    disabled
      ? selectTheme('colors.neutral.40')
      : selectTheme('colors.neutral.80')};
  height: 64px;
  padding: ${selectTheme('spacingX.5xl')};
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
  display: flex;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};

  &::after {
    color: ${selectTheme('colors.neutral.90')};
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    height: 6px;
    position: absolute;
    right: ${selectTheme('spacingX.7xl')};
    transform: rotate(135deg);
    vertical-align: top;
    width: 6px;
  }

  &:focus-within {
    background: ${selectTheme('colors.neutral.0')};
    box-shadow: ${selectTheme('shadow.sm')};
    transition: all ${selectTheme('motion.sm')};
  }
`
